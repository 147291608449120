// eslint-disable-next-line import/no-anonymous-default-export
export default {
  topNavigation: {
    news: { en: 'News', cs: 'Novinky' },
    announcements: { en: 'Announcements', cs: 'Oznámení', sk: 'Oznamy' },
    career: { en: 'Career', cs: 'Kariéra' },
    media: { en: 'Media', cs: 'Média', sk: 'Médiá' },
    contact: { en: 'Contact', cs: 'Kontakt' },
  },
  mainNavigation: {
    services: { en: 'Services', cs: 'Služby' },
    customers: { en: 'Customers', cs: 'Zákazníci' },
    locations: {
      en: 'Locations',
      cs: 'Sběrné dvory a provozovny',
      sk: 'Prevádzky',
    },
    about: { en: 'About us', cs: 'O společnosti', sk: 'O spoločnosti' },
    search: { en: 'Search', cs: 'Vyhledávání' },
  },
  submenu: {
    customers: {
      individual: { en: 'Individuals', cs: 'Občané', sk: 'Občania' },
      business: {
        en: 'Enterprise',
        cs: 'Podnikatelé a firmy',
        sk: 'Podnikatelia a firmy',
      },
      community: {
        en: 'Community',
        cs: 'Obce a státní správa',
        sk: 'Obce a štátna správa',
      },
    },
  },
  locationType: {
    establishment: { cs: 'Provozovna', en: 'Establishment' },
    collection_yard: { cs: 'Sběrný dvůr', en: 'Collection yard' },
    dump: { cs: 'Skládka', en: 'Dump' },
  },
  searchLocation: {
    en: 'Search for collection yard / establishment',
    cs: 'Vyhledejte sběrný dvůr / provozovnu',
    sk: 'Vyhľadajte zberný dvor/prevádzku',
  },
  searchResults: {
    en: (count: number) => `${count} result${count !== 1 ? 's' : ''}`,
    cs: (count: number) =>
      `${count} ${
        count === 1
          ? 'výsledek'
          : count === 0 || count > 4
          ? 'výsledků'
          : 'výsledky'
      }`,
  },
  page404: {
    page: { cs: 'Stránka', en: 'Page' },
    notFound: { cs: 'nenalezena', en: 'not found' },
    line1: {
      cs: 'Je nám líto, ale požadovaná stránka nebyla nalezena.',
      en: "We're sorry, the page you requested was not found.",
    },
    line2: {
      cs: 'Ujistěte se, že jste neudělali chybu v URL adrese. Je možné, že byla stránka přemístěna nebo odstraněna.',
      en: "Make sure you didn't make a mistake in the URL.",
    },
    line3: {
      cs: 'Tlačítkem se vrátíte na úvodní stránku',
      en: 'The page may have been moved or deleted.',
    },
    back: { cs: 'Vrátit se na hlavní stránku', en: 'Return to Homepage' },
  },
  placeholders: {
    chooseRegion: { en: 'Choose region', cs: 'Vyberte kraj' },
    choosePosition: { en: 'Choose position', cs: 'Vyberte pozici' },
  },
  articles: {
    all: { en: 'All news', cs: 'Všechny aktuality', sk: 'Všetky aktuality' },
    similar: { en: 'More articles on this topic', cs: 'Další články na téma' },
    read: { en: 'Whole article', cs: 'Celý článek' },
    continueReading: { en: 'Continue reading', cs: 'Pokračovat ve čtení' },
  },
  contact: {
    ourLocationsTitle: {
      en: 'Establishments| and collection yards',
      cs: 'Naše provozovny| a sběrné dvory',
      sk: 'Naše prevádzky| a zberné dvory',
    },
    subsidiaryLocationsTitle: {
      en: 'Workplaces| and collection yards',
      cs: 'Pracoviště| a sběrné dvory',
    },
    showAll: { en: 'Show all', cs: 'Zobrazit vše', sk: 'Zobrazit všetko' },
    business: { en: 'Establishments', cs: 'Provozovny', sk: 'Prevádzky' },
    collectionYard: {
      en: 'Collection yards and landfills',
      cs: 'Sběrné dvory a skládky',
      sk: 'Zberné dvory a skládky',
    },
    contactLabel: { en: 'Contact', cs: 'Kontakt' },
    contactsLabel: { en: 'Contacts', cs: 'Kontakty' },
    purchaseBoxTitle: {
      en: 'More information',
      cs: 'Další informace',
      sk: 'Ďalšie informácie',
    },
    loadMore: { cs: 'Zobrazit více', sk: 'Zobraziť viac', en: 'Show more' },
  },
  media: {
    label: { en: 'for press', cs: 'pro média', sk: 'pre médiá' },
    contact: {
      en: 'Contacts',
      cs: 'Kontakty pro novináře',
      sk: 'Kontakty pre novinárov',
    },
    toDownload: { en: 'Downloads', cs: 'Ke stažení', sk: 'Na stiahnutie' },
    pressReleases: {
      en: 'Press releases',
      cs: 'Tiskové zprávy',
      sk: 'Oznamy',
    },
    videos: { en: 'Video', cs: 'Videa', sk: 'Videá' },
  },
  home: {
    wantToKnowMore: {
      en: 'I want to know more',
      cs: 'Chci vědět více',
      sk: 'Chcem vedieť viac',
    },
    responsibility: {
      en: 'Social responsibility',
      cs: 'Společenská odpovědnost',
      sk: 'Spoločenská zodpovednosť',
    },
  },
  footer: {
    dataProtection: {
      en: 'Personal data protection',
      cs: 'Ochrana osobních údajů',
      sk: 'Ochrana osobných údajov',
    },
    whistleblowing: {
      en: 'Reporting of wrongdoing',
      cs: 'Oznamování protiprávního jednání',
      sk: 'Oznamovanie porušenia',
    },
    cookies: {
      en: 'Cookies policy',
      cs: 'Informace o cookies',
      sk: 'Informácie o cookies',
    },
    infoLink: {
      en: 'Customer service',
      cs: 'Infolinka zdarma',
      sk: 'Infolinka zadarmo',
    },
  },
  form: {
    requiredField: { en: 'This field is required', cs: 'Toto pole je povinné' },
    requiredLabel: { en: 'required field', cs: 'povinné pole' },
  },
  jobForm: {
    title: {
      en: 'Send us a contact',
      cs: 'Pošlete nám kontakt',
      sk: 'Pošlite nám kontakt',
    },
    subtitle: {
      en: 'Are you interested in working with us? We will get back to you...',
      cs: 'Máte zájem u nás pracovat? Ozveme se vám…',
      sk: 'Máte záujem pracovať u nás? Ozveme sa vám...',
    },
    name: { en: 'Name', cs: 'Jméno a příjmení', sk: 'Meno a priezvisko' },
    phone: { en: 'phone number', cs: 'tel. číslo' },
    email: { en: 'e-mail', cs: 'e-mail' },
    wrongEmail: {
      en: 'Not a valid e-mail',
      cs: 'Nesprávný formát e-mailu',
      sk: 'Nesprávny formát e-mailu',
    },
    note: {
      en: 'Note',
      cs: 'Poznámka',
    },
    confirmLabel: {
      en: 'By clicking Send button you agree with processing of your personal data. More information can be found |here',
      cs: 'Kliknutím na tlačítko Odeslat souhlasíte se zpracováním svých osobních údajů. Více informací naleznete |zde',
      sk: 'Kliknutím na tlačítko Odoslať súhlasíte so spracovaním vašich osobných údajov. Viac informácií nájdete |tu',
    },
    button: {
      en: 'Send',
      cs: 'Odeslat',
      sk: 'Odoslať',
    },
    submitMessage: {
      cs: 'Děkujeme za odeslání Vaší žádosti nebo dotazu. V nejbližších dnech Vás budeme kontaktovat.',
      en: 'Thank you for submitting your request or inquiry. We will be in contact with you in the next few days.',
      sk: 'Děkujeme za odeslání Vaší žádosti nebo dotazu. V nejbližších dnech Vás budeme kontaktovat.',
    },
    errorMessage: {
      cs: 'Bohužel se formulář nepovedlo odeslat. Zkuste to prosím později.',
      en: 'Unfortunately, the form was not able to be sent. Please try again later.',
      sk: 'Bohužel se formulář nepovedlo odeslat. Zkuste to prosím později.',
    },
  },
  contactForm: {
    orderService: { cs: 'Objednat službu', en: 'Order service' },
    additionalInfo: {
      cs: 'Doplňující informace',
      sk: 'Doplňujúce informácie',
      en: 'Additional information',
    },
    citySelectPlaceholder: {
      cs: 'Vyberte město/obec',
      sk: 'Vyberte mesto/obec',
      en: 'Select city',
    },
    citySelectNoCityFound: {
      cs: 'město/obec nenalezeno',
      sk: 'mesto/obec nenájdené',
      en: 'no city found',
    },
    info: {
      en: 'Do you need help with choosing the right service? Contact us!',
      cs: 'Potřebujete poradit nebo objednat službu? Kontaktujte nás.',
      sk: 'Potrebujete poradiť alebo objednať službu? Kontaktujte nás.',
    },
    name: { en: 'Name', cs: 'Jméno a příjmení', sk: 'Meno a priezvisko' },
    phone: { en: 'phone number', cs: 'tel. číslo' },
    email: { en: 'e-mail', cs: 'e-mail' },
    wrongEmail: {
      en: 'Not a valid e-mail',
      cs: 'Nesprávný formát e-mailu',
      sk: 'Nesprávny formát e-mailu',
    },
    service: { en: 'Choose service', cs: 'Vyberte službu' },
    submitButton: { en: 'Contact us', cs: 'Kontaktuje nás' },
    region: {
      en: 'Choose region',
      cs: 'Vyberte kraj, kde máte o službu zájem',
      sk: 'Vyberte kraj, kde máte o službu záujem.',
    },
    confirmLabel: {
      en: 'By clicking Contact button you agree with processing of your personal data. More information can be found |here',
      cs: 'Kliknutím na tlačítko Kontaktujte mě souhlasíte se zpracováním svých osobních údajů. Více informací naleznete |zde',
      sk: 'Kliknutím na tlačítko Kontaktujte ma súhlasíte so spracovaním vašich osobných údajov. Viac informácií nájdete |tu',
    },
    submitMessage: {
      cs: 'Vaše poptávka byla úspěšně odeslána. V nejbližších dnech Vás bude kontaktovat naše obchodní oddělení. Děkujeme.',
      en: 'Your request has been successfully submitted. Our sales department will contact you in the next few days. Thank you.',
      sk: 'Vaše poptávka byla úspěšně odeslána. V nejbližších dnech Vás bude kontaktovat naše obchodní oddělení. Děkujeme.',
    },
    errorMessage: {
      cs: 'Bohužel se formulář nepovedlo odeslat. Zkuste to prosím později.',
      en: 'Unfortunately, the form was not able to be sent. Please try again later.',
      sk: 'Bohužel se formulář nepovedlo odeslat. Zkuste to prosím později.',
    },
  },
  about: {
    values: { cs: 'Hodnoty naší společnosti', en: 'Company Values' },
    aveLogo: {
      cs: 'AVE logo a grafický manuál',
      en: 'Ave Logo and Digital Assets',
    },
  },
  career: {
    quotesTitle: {
      cs: 'Co říkají lidé na práci u nás?',
      en: 'What do people say about working with us?',
    },
    quoteTypes: {
      0: {
        cs: 'týmová práce, partnerství a ohleduplnost',
        en: 'team work and partnership',
      },
      1: {
        cs: 'profesionalita a výkonnost',
        en: 'professionality and performance',
      },
      2: { cs: 'flexibilita a růst', en: 'flexibility and growth' },
    },
    header: {
      about: { cs: 'O nás', en: 'About us', sk: 'O nás' },
      work: { cs: 'Práce v AVE', en: 'Work in AVE', sk: 'Práce v AVE' },
      values: {
        cs: 'Firemní hodnoty',
        en: 'Company values',
        sk: 'Firemní hodnoty',
      },
    },
    whyLabel: { cs: 'Proč AVE?', en: 'Why AVE?' },
    whatWeOffer: { cs: 'Co |nabízíme', en: 'What do |we offer' },
    responsibleLabel1: {
      cs: 'Jsme [společensky] ',
      en: 'We are [socially] ',
    },
    responsibleLabel2: {
      cs: '[a ekologicky] odpovědná společnost',
      en: '[and ecologically] responsible',
    },
    stories: {
      title: { cs: 'Příběhy kolegů', en: 'Stories', sk: 'Příběhy kolegů' },
      all: { cs: 'Všechny příběhy', en: 'All stories', sk: 'Všechny příběhy' },
    },
    lookingFor: {
      title: { cs: 'Hledáme', en: 'Looking for', sk: 'Hledáme' },
      allOffers: { cs: 'Všechny nabídky', en: 'All positions' },
      freePositions: { cs: 'Volné pozice', en: 'Open positions' },
    },
  },
  request: { en: 'Request', cs: 'Žádost', sk: 'Žádost' },
  newsSocialIcons: {
    youtube: {
      cs: 'https://www.youtube.com/@aveczodpadovehospodarstvis1865',
      en: 'https://www.youtube.com/@aveczodpadovehospodarstvis1865',
      sk: 'https://www.youtube.com/@aveczodpadovehospodarstvis1865',
    },
    linkedin: {
      cs: 'https://www.linkedin.com/company/ave-cz-odpadove-hospodarstvi-sro/',
      en: 'https://www.linkedin.com/company/ave-cz-odpadove-hospodarstvi-sro/',
      sk: 'https://www.linkedin.com/company/ave-cz-odpadove-hospodarstvi-sro/',
    },
  },
}
