// Next.js
import Link from '../../Link'
import { useRouter } from 'next/router'

// React
import { useEffect } from 'react'

// Framer Motion
import { AnimatePresence } from 'framer-motion'

// React components (svg)
import CloseIcon from '../../svgs/icons/CloseIcon'
import YoutubeIcon from '../../svgs/icons/YoutubeIcon'
import LinkedInIcon from '../../svgs/icons/LinkedInIcon'
import RightArrowIcon from '../../svgs/icons/RightArrowIcon'

// React components
import LanguageSwitcher from './LanguageSwitcher'
import SocialIconsBar from '../../SocialIconsBar'
import PhoneContact from '../../PhoneContact'
import SubNavList from './SubNavList'

// Styled Components
import {
  HamburgerMenuWrapper,
  Header,
  Nav,
  Footer,
  CloseIconWrapper,
  NavList,
  NavItem,
  NavLink,
} from './HamburgerMenu.styles'

import {
  NavItem as SubNavItem,
  NavLink as SubNavLink,
} from './SubNavList/SubNavList.styles'

// Store
import useStore from '../../../utils/store'

// Constants
import useDictionaryLine from '../../../hooks/useDictionary'
import dictionary from '../../../utils/dictionary'
import { Api } from '../../../types/api'
import { Fragment } from 'react'
import { compose, flatten, map } from 'ramda'
import { REGIONS, REGIONS_TRANSLATION_MAP } from '../../../utils/constants'

import { TABS_ABOUT } from '../../../utils/constants'

type Props = {
  serviceList?: Api.ServiceList
  career?: boolean
}

const HamburgerMenu = ({ serviceList, career = false }: Props) => {
  const closeMenu = useStore(state => state.closeMenu)
  const subNav = useStore(state => state.subNav)
  const setSubNav = useStore(state => state.setSubNav)
  const router = useRouter()
  const servicesLocale = useDictionaryLine(dictionary.mainNavigation.services)
  const customersLocale = useDictionaryLine(dictionary.mainNavigation.customers)
  const locationsLocale = useDictionaryLine(dictionary.mainNavigation.locations)
  const mediaLocale = useDictionaryLine(dictionary.topNavigation.media)
  const contactLocale = useDictionaryLine(dictionary.topNavigation.contact)
  const annoucmentsLocale = useDictionaryLine(
    dictionary.topNavigation.announcements,
  )
  const newsLocale = useDictionaryLine(dictionary.topNavigation.news)
  const aboutLocale = useDictionaryLine(dictionary.mainNavigation.about)
  const whyAVE = useDictionaryLine(dictionary.career.whyLabel)
  const lookingForTitle = useDictionaryLine(dictionary.career.lookingFor.title)
  const storiesLocale = useDictionaryLine(dictionary.career.stories.title)
  const workLocale = useDictionaryLine(dictionary.career.header.work)
  // const individualLocale = useDictionaryLine(
  //   dictionary.submenu.customers.individual,
  // )
  // const communityLocale = useDictionaryLine(
  //   dictionary.submenu.customers.community,
  // )
  // const businessLocale = useDictionaryLine(
  //   dictionary.submenu.customers.business,
  // )

  const close = () => {
    setSubNav('')
    closeMenu()
  }

  useEffect(() => {
    if (router.locale === 'en' && subNav === 'locations') {
      setSubNav('')
    }
  }, [router.locale])

  const getContent = () => {
    switch (subNav) {
      case 'about':
        return (
          <SubNavList backText={aboutLocale}>
            {TABS_ABOUT.map(item => {
              return (
                <SubNavItem key={item.id}>
                  <Link href={`/o-spolecnosti${item.href}`}>
                    <SubNavLink onClick={close}>{item.title}</SubNavLink>
                  </Link>
                </SubNavItem>
              )
            })}
          </SubNavList>
        )
      case 'locations':
        return (
          <SubNavList backText={locationsLocale}>
            {REGIONS.map(region => {
              return (
                <SubNavItem key={region}>
                  <Link
                    href={`/kontakt/${REGIONS_TRANSLATION_MAP[region]}#provozovny-a-sberne-dvory`}
                  >
                    <SubNavLink onClick={close}>{region}</SubNavLink>
                  </Link>
                </SubNavItem>
              )
            })}
          </SubNavList>
        )
      case 'services':
        return (
          <SubNavList backText={servicesLocale}>
            {serviceList?.serviceCategories.data?.map(category => {
              return (
                <Fragment key={category.id}>
                  <SubNavItem>
                    <Link href={`/sluzby/${category.attributes.slug}`}>
                      <SubNavLink onClick={close}>
                        {category.attributes.name}
                      </SubNavLink>
                    </Link>
                  </SubNavItem>
                  {
                    // @TODO we don't want to display subcategories on mobile?
                    //   category.attributes.services.data?.map(service => {
                    //   return (
                    //     <NavItem key={service.id}>
                    //       <Link
                    //         href={`/sluzby/${
                    //           category.attributes.slug
                    //         }#${service.attributes.name
                    //           .normalize('NFD')
                    //           .replace(/[\u0300-\u036f]/g, '')
                    //           .split(' ')
                    //           .join('-')
                    //           .toLowerCase()}`}
                    //       >
                    //         <NavLink onClick={close}>
                    //           {service.attributes.name}
                    //         </NavLink>
                    //       </Link>
                    //     </NavItem>
                    //   )
                    // })
                  }
                </Fragment>
              )
            })}
          </SubNavList>
        )
      case 'customers':
        const services = compose(
          flatten,
          map(
            (category: Api.ServiceCategory) =>
              category.attributes.services.data,
          ),
        )(serviceList?.serviceCategories.data || [])
        return (
          <SubNavList backText={customersLocale}>
            {[
              // [
              //   (service: Api.Service) => service.attributes.forIndividual,
              //   individualLocale,
              // ],
              // [
              //   (service: Api.Service) => service.attributes.forCommunity,
              //   communityLocale,
              // ],
              // [
              //   (service: Api.Service) => service.attributes.forEntrepreneur,
              //   businessLocale,
              // ],
              [
                // @TODO we don't want to display subcategories on mobile?
                () => true,
                '',
              ],
            ].map((block: any) => {
              return (
                <Fragment key={block[1]}>
                  {
                    // <SubNavItem>{block[1]}</SubNavItem>
                  }
                  {services.map(service => {
                    return (
                      <SubNavItem key={service.id}>
                        <Link
                          href={`/sluzby/${
                            service.attributes.category.data.attributes.slug
                          }#${service.attributes.name
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .split(' ')
                            .join('-')
                            .toLowerCase()}`}
                        >
                          <SubNavLink onClick={close}>
                            {service.attributes.name}
                          </SubNavLink>
                        </Link>
                      </SubNavItem>
                    )
                  })}
                </Fragment>
              )
            })}
          </SubNavList>
        )
      default:
        return (
          <Nav
            initial={{
              x: '-100%',
            }}
            animate={{
              x: 0,
              transition: {
                duration: 0.4,
              },
            }}
            exit={{
              x: '-100%',
            }}
          >
            {career ? (
              <NavList>
                <NavItem>
                  <Link href='/o-ave' passHref legacyBehavior>
                    <NavLink onClick={close}>{whyAVE.replace('?', '')}</NavLink>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link href='/prace-v-ave' passHref legacyBehavior>
                    <NavLink onClick={close}>{workLocale}</NavLink>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link href='/hledame' passHref legacyBehavior>
                    <NavLink onClick={close}>{lookingForTitle}</NavLink>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link href='/pribehy-kolegu' passHref legacyBehavior>
                    <NavLink onClick={close}>{storiesLocale}</NavLink>
                  </Link>
                </NavItem>
              </NavList>
            ) : (
              <NavList>
                <NavItem>
                  <NavLink
                    as='div'
                    onClick={() => {
                      setSubNav('services')
                    }}
                  >
                    {servicesLocale} <RightArrowIcon />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    as='div'
                    onClick={() => {
                      setSubNav('customers')
                    }}
                  >
                    {customersLocale} <RightArrowIcon />
                  </NavLink>
                </NavItem>
                {router.locale !== 'en' && (
                  <NavItem>
                    <NavLink
                      as='div'
                      onClick={() => {
                        setSubNav('locations')
                      }}
                    >
                      {locationsLocale} <RightArrowIcon />
                    </NavLink>
                  </NavItem>
                )}
                <NavItem>
                  <NavLink
                    as='div'
                    onClick={() => {
                      setSubNav('about')
                    }}
                  >
                    {aboutLocale} <RightArrowIcon />
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <Link href='/o-spolecnosti' passHref legacyBehavior>
                    <NavLink onClick={close}>{aboutLocale}</NavLink>
                  </Link>
                </NavItem> */}
                <NavItem>
                  <Link href='/novinky' passHref legacyBehavior>
                    <NavLink onClick={close}>{newsLocale}</NavLink>
                  </Link>
                </NavItem>
                {process.env.NEXT_PUBLIC_DEFAULT_LOCALE === 'sk' && (
                  <NavItem>
                    <Link href='/media#oznamy' passHref legacyBehavior>
                      <NavLink onClick={close}>{annoucmentsLocale}</NavLink>
                    </Link>
                  </NavItem>
                )}
                {process.env.NEXT_PUBLIC_DEFAULT_LOCALE !== 'sk' && (
                  <NavItem>
                    <Link
                      href='https://kariera.ave.cz/'
                      passHref
                      legacyBehavior
                    >
                      <NavLink>Kariéra</NavLink>
                    </Link>
                  </NavItem>
                )}

                <NavItem>
                  <Link href='/media' passHref legacyBehavior>
                    <NavLink onClick={close}>{mediaLocale}</NavLink>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link href='/kontakt' passHref legacyBehavior>
                    <NavLink onClick={close}>{contactLocale}</NavLink>
                  </Link>
                </NavItem>
              </NavList>
            )}
          </Nav>
        )
    }
  }

  return (
    <HamburgerMenuWrapper>
      <Header>
        <LanguageSwitcher />
        <CloseIconWrapper onClick={closeMenu}>
          <CloseIcon />
        </CloseIconWrapper>
      </Header>
      <AnimatePresence>{getContent()}</AnimatePresence>
      <Footer>
        <PhoneContact />
        <SocialIconsBar
          icons={[
            { icon: <YoutubeIcon />, href: 'https://www.youtube.com/' },
            { icon: <LinkedInIcon />, href: 'https://cz.linkedin.com/' },
          ]}
        />
      </Footer>
    </HamburgerMenuWrapper>
  )
}
export default HamburgerMenu
