import NextLink from 'next/link'
import { useRouter } from 'next/router'
import translateMap from '../translateMap'
import { getLangTranslate } from './LanguageSwitcher/LanguageSwitcher'

const Link = (props: any) => {
  const { locale } = useRouter()
  const to =
    locale === 'en' ? getLangTranslate(translateMap)(props.href) : props.href
  return <NextLink {...props} href={to} locale={locale} passHref />
}

export default Link
